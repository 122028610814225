// eslint-disable-next-line no-use-before-define
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Switch, Route, Redirect } from 'react-router-dom';
import AppLayout from 'src/components/AppLayout';
import { templatesAdapterByOwnerOrganizationIdFactory } from '@biotmed/data-components';
import { FilterV2 } from '@biotmed/sdk-api-provider/lib/types/device';
import { selectors } from '../../../redux/data/user';
import AuthSwitch from '../../Auth';
import RoutesEnum from '../modules/routes';
import Patients from '../../Patients';
import OrganizationUsers from '../../OrganizationUsers';
import Caregivers from '../../Caregivers';
import ChangePasswordPage from '../../ChangePasswordPage/ChangePasswordPage';
import Devices from '../../Devices';
import GenericEntities from '../../GenericEntities';
import { Spin, SpinBox } from './styled';

interface RoutePagesProps {
  isLoggedIn: boolean;
}

export const PublicPage: React.FC<RoutePagesProps> = props => {
  const { isLoggedIn } = props;

  return !isLoggedIn ? <AuthSwitch /> : <Redirect to="/" />;
};

export const PrivatePage: React.FC<RoutePagesProps> = props => {
  const { isLoggedIn } = props;
  const intl = useIntl();

  const ownerOrganization = useSelector(selectors.selectOwnerOrganization);
  const syntheticOwnerOrganization = useMemo(
    // Here we destruct the object because otherwise when cleaning the object from empty strings, there is a problem
    () => (ownerOrganization ? { _ownerOrganization: { ...ownerOrganization } } : {}),
    [ownerOrganization],
  );

  const templatesAdapter = useMemo(() => {
    return ownerOrganization?.id ? templatesAdapterByOwnerOrganizationIdFactory(ownerOrganization.id) : undefined;
  }, [ownerOrganization]);

  const narrowDownFilters = useMemo(() => {
    return ownerOrganization?.id
      ? ({
          '_ownerOrganization.id': {
            in: [ownerOrganization.id],
          },
        } as Record<string, FilterV2>)
      : undefined;
  }, [ownerOrganization]);

  return isLoggedIn ? (
    <AppLayout>
      {!ownerOrganization ? (
        // TODO: Remove this loader when the backend supports sending ownerOrganization with the user object
        <SpinBox>
          <Spin />
        </SpinBox>
      ) : (
        <Switch>
          <Route
            exact
            path={`/${RoutesEnum.PATIENTS}`}
            render={() => (
              <Patients
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            )}
          />
          <Route
            exact
            path={`/${RoutesEnum.CAREGIVERS}`}
            render={() => (
              <Caregivers
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            )}
          />
          <Route
            exact
            path={`/${RoutesEnum.ORGANIZATION_USERS}`}
            render={() => (
              <OrganizationUsers
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            )}
          />
          <Route
            exact
            path={`/${RoutesEnum.DEVICES}`}
            render={() => (
              <Devices
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            )}
          />
          <Route
            exact
            path={`/${RoutesEnum.GENERIC_ENTITIES}`}
            render={() => (
              <GenericEntities
                mainTitle={intl.formatMessage({ id: 'generic.entities.page.main.title', defaultMessage: 'General' })}
                narrowDownFilters={narrowDownFilters}
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            )}
          />
          {/* <Route exact path={`${path}${routes.MEASUREMENTS}`} component={() => <MeasurementsPage />} /> */}
          <Route exact path={`/${RoutesEnum.CHANGE_PASSWORD}`} render={() => <ChangePasswordPage />} />
          <Redirect to={`/${RoutesEnum.PATIENTS}`} />
        </Switch>
      )}
    </AppLayout>
  ) : (
    <Redirect to={{ pathname: `/${RoutesEnum.AUTH}` }} />
  );
};
