import {
  CaregiverCrud,
  caregiverCrudReducer,
  caregiverCrudSaga,
  caregiverCrudStateKey,
  caregiverCrudGetInitialState,
} from '@biotmed/data-components';

export { CaregiverCrud, caregiverCrudReducer, caregiverCrudSaga, caregiverCrudStateKey, caregiverCrudGetInitialState };

export default CaregiverCrud;
