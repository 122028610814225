import { EnvType } from '@biot/env';
import { splitString } from 'src/utils/envVariableUtils';

declare global {
  interface Window {
    env: any;
  }
}

const env: EnvType = { ...process.env, ...window.env };

const APP_CONFIG = Object.freeze({
  ...process.env,
  API_URL: env.REACT_APP_API_URL,
  APP_DOMAIN: env.REACT_APP_DOMAIN,
  TERMS: env.REACT_APP_TERMS,
  PRIVACY: env.REACT_APP_PRIVACY,
  DEFAULT_LOCALE: env.REACT_APP_DEFAULT_LOCALE,
  AVAILABLE_LOCALES: splitString(env.REACT_APP_AVAILABLE_LOCALES),
  TRANSLATION_FILES_BASE_URL: env.REACT_APP_TRANSLATION_FILES_BASE_URL,
  IDLE_TIMEOUT_MS: env.REACT_APP_IDLE_TIMEOUT_MS,
  BRANDING_THEME_URL: env.REACT_APP_BRANDING_THEME_URL,
  BRANDING_LOGO_URL: env.REACT_APP_BRANDING_LOGO_URL,
});

export default APP_CONFIG;
