// eslint-disable-next-line no-use-before-define
import React from 'react';
import { PoweredByBioT, ConnectionAlert, GlobalError } from '@biotmed/base-components';
import { ErrorBoundary } from 'react-error-boundary';
import { MainContent, Footer, Header, StyledLayout } from './AppLayout.styled';
import TopMenu from './TopMenu';

interface AppLayoutProps {}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => (
  <StyledLayout>
    <ErrorBoundary FallbackComponent={props => <GlobalError {...props} />}>
      <Header>
        <TopMenu />
      </Header>
      <MainContent>
        <ConnectionAlert />
        {children}
      </MainContent>
    </ErrorBoundary>

    <Footer>
      <PoweredByBioT />
    </Footer>
  </StyledLayout>
);

export default AppLayout;
