import {
  DeviceCrud,
  deviceCrudReducer,
  deviceCrudSaga,
  deviceCrudStateKey,
  deviceCrudGetInitialState,
} from '@biotmed/data-components';

export { deviceCrudReducer, deviceCrudSaga, deviceCrudStateKey, deviceCrudGetInitialState };

export default DeviceCrud;
