import styled from 'styled-components';
import { convertTypographySettingsToCss, fontWeightNameMappings } from '@biotmed/base-components';

export const MainContent = styled.div`
  flex: 1;
  background: ${props => props.theme.palette.grayScale.lightest};
  overflow-y: auto;
  .generic-table-crud-page {
    height: 100%;
  }
`;
export const Spacer = styled.div`
  flex-grow: 2;
`;
export const Header = styled.div`
  flex: 0;
  height: 53px;
  background: ${props => props.theme.palette.grayScale.lightest};
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;

export const Footer = styled.div`
  padding: 32px 20px;
`;
export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export const UserName = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body2)};
  font-weight: ${fontWeightNameMappings.light};
  line-height: 1.3;
`;
export const UserRole = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.button.small)};
  font-size: 1rem;
  font-weight: ${fontWeightNameMappings.light};
  line-height: 1.1;
`;

export const TopMenu = styled.div`
  flex: 1;
`;

export const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 2px 13px;
  border: solid 1px ${props => props.theme.palette.grayScale.medium};
  border-radius: 3px;
`;

export const UserDetails = styled.div``;

export const LogoContainer = styled.div`
  background-color: ${props => props.theme.palette.grayScale.lightest};
  border-radius: 8px;
  padding: 5px;
`;
export const StyledLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
