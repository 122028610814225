import { all, put, call, takeLatest, select, delay } from 'redux-saga/effects';
import UmsLogic, { LoginResponse } from '@biot/ums-js-logic';
import { actions as loginActions } from '../../login';
import { actions, selectors } from './slice';

function* handleLogin() {
  const isLoggedIn: boolean = yield call(UmsLogic.isLoggedIn);
  const isUserRemembered: boolean = yield call(UmsLogic.isUserRemembered);

  if (!isLoggedIn && !isUserRemembered) {
    return;
  }

  try {
    const response: LoginResponse = yield call(UmsLogic.appStartLogin);
    yield put(loginActions.loginSuccess({ loginResponse: response }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error while trying to login with token: ', e);
  }
}

function* waitForMessages() {
  let loaded: boolean = yield select(selectors.getIsMessagesLoaded);
  while (!loaded) {
    yield delay(1000);
    loaded = yield select(selectors.getIsMessagesLoaded);
  }
}

function* onAppStart() {
  // const { loginResult, fetchMessagesSuccessResult }
  yield all({
    loginResult: call(handleLogin),
    fetchMessagesSuccessResult: call(waitForMessages),
  });

  yield put(actions.appStartFinish(false));
}

export default function* watchAppActions() {
  yield all([takeLatest(actions.appStart, onAppStart)]);
}
