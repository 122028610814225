import { combineReducers } from 'redux';
import { fork, all } from 'redux-saga/effects';
import { RootState } from '../reducer';
import { DATA_STATE_KEY } from './constants';
import {
  STATE_KEY as userStateKey,
  reducer as userReducer,
  getInitialState as getUserInitialState,
  saga as userSaga,
} from './user';
import {
  STATE_KEY as appStateKey,
  reducer as appReducer,
  saga as appSaga,
  getInitialState as getAppInitialState,
} from './app';
import {
  STATE_KEY as loginStateKey,
  reducer as loginReducer,
  saga as loginSaga,
  getInitialState as getLoginInitialState,
} from './login';

/*
import {
  STATE_KEY as measurementsStateKey,
  reducer as measurementsReducer,
  saga as measurementsSaga,
  getInitialState as getMeasurementsInitialState,
} from './measurements';
*/

export const STATE_KEY = DATA_STATE_KEY;

export const getDataInitialState = (state?: RootState) => ({
  [appStateKey]: getAppInitialState(state),
  [userStateKey]: getUserInitialState(state),
  [loginStateKey]: getLoginInitialState(state),
  // [measurementsStateKey]: getMeasurementsInitialState(state),
});

export const reducer = combineReducers({
  [appStateKey]: appReducer,
  [userStateKey]: userReducer,
  [loginStateKey]: loginReducer,
  // [measurementsStateKey]: measurementsReducer,
});

export const sagas = function* root() {
  yield all([fork(appSaga), fork(loginSaga), fork(userSaga) /* , fork(measurementsSaga) */]);
};
