import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { activateUserUrl } from '@biotmed/auth-pages';
import { SessionExpiredModal, standardTheme, Theme, useIdleTimer } from '@biotmed/base-components';
import {
  attachCrudComponentRenderer,
  dataComponentsParams,
  EntityTypeEnum,
  entityTypeToActionsMapper,
  entityTypeToCrudActionsToggleMapper,
  expandCrudComponentRenderer,
  expandCrudSliceMapper,
  PortalTypeEnum,
  CrudActionsEnum,
  EntityType,
} from '@biotmed/data-components';
import intl from '@biotmed/i18n';

import LanguageProvider from 'src/components/LanguageProvider';
import AppConfig from 'src/config/AppConfig';
import history from 'src/utils/history';
import { getThemeOverrideIfExists } from 'src/themes/theme';

import GlobalStyle from '../../global.styled';
import { actions as loginActions } from '../../redux/data/login';
import { selectors } from '../../redux/data/login/modules/slice';
import AppComponent from '../Navigation';
import RoutesEnum from '../Navigation/modules/routes';

interface AppProps {}

const App: React.FC<AppProps> = () => {
  // Create redux store with session state

  const dispatch = useDispatch();
  const isLoggedIn: boolean = useSelector(selectors.getIsLoggenIn);
  const baseURL = window.location.origin;
  // TODO: baseURL and activateUserUrl include "/" and RoutesEnum.AUTH doesn't. Maybe the "/" should be consistent throughout.
  const emailConfirmationLandingPage = () =>
    `${baseURL}/${RoutesEnum.AUTH}${activateUserUrl}?lang=${intl.current.locale}`;

  const hiddenInputFieldsMapper = (entityType: EntityType | undefined) => {
    if (
      [
        EntityTypeEnum.PATIENT,
        EntityTypeEnum.CAREGIVER,
        EntityTypeEnum.ORGANIZATION_USER,
        EntityTypeEnum.DEVICE,
        EntityTypeEnum.USAGE_SESSION,
        EntityTypeEnum.GENERIC_ENTITY,
      ].includes(entityType as EntityTypeEnum)
    ) {
      return ['_ownerOrganization'];
    }
    return [];
  };

  const promptTimeoutInMs = 30000;
  const [idleTimerManager] = useIdleTimer({
    isLoggedIn,
    timeout: AppConfig.IDLE_TIMEOUT_MS,
    promptTimeout: promptTimeoutInMs, // The time when the user becomes idle until the onIdle function is called
    onIdle: () => dispatch(loginActions.logout()),
  });

  useEffect(() => {
    dataComponentsParams.init({
      applicationType: PortalTypeEnum.ORGANIZATION_PORTAL,
      emailConfirmationLandingPage,
      expandCrudComponentRenderer,
      expandCrudSliceMapper,
      attachCrudComponentRenderer,
      hiddenInputFieldsMapper,
      actionsMapper: entityTypeToActionsMapper,
      crudActionsToggleMapper: (entityType?: EntityType) => {
        const mapper = entityTypeToCrudActionsToggleMapper(entityType);

        if (entityType === EntityTypeEnum.DEVICE) {
          return { ...mapper, [CrudActionsEnum.ADD]: false };
        }
        if (entityType === EntityTypeEnum.USAGE_SESSION) {
          return { ...mapper, [CrudActionsEnum.ATTACH]: false, [CrudActionsEnum.DETACH]: false };
        }
        if (entityType === EntityTypeEnum.ORGANIZATION) {
          return {
            ...mapper,
            [CrudActionsEnum.ADD]: false,
            [CrudActionsEnum.ATTACH]: false,
            [CrudActionsEnum.DETACH]: false,
            [CrudActionsEnum.EDIT]: false,
            [CrudActionsEnum.DELETE]: false,
          };
        }
        return mapper;
      },
      idleTimerManager: {
        pause: idleTimerManager?.pause,
        activate: idleTimerManager?.activate,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      idleTimerManager?.reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const [theme, setTheme] = useState<Theme>();

  useEffect(() => {
    async function initTheme() {
      const updatedTheme = await getThemeOverrideIfExists();
      setTheme(updatedTheme);
    }

    initTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router history={history}>
      {theme && (
        <ThemeProvider theme={theme}>
          <LanguageProvider>
            <>
              <GlobalStyle />
              <SessionExpiredModal
                open={!!idleTimerManager?.isPrompted}
                onContinue={() => idleTimerManager?.activate()}
                onLogOff={() => {
                  dispatch(loginActions.logout());
                  idleTimerManager?.setIsPrompted(false);
                }}
                countdownTimeout={promptTimeoutInMs}
              />
              <AppComponent />
            </>
          </LanguageProvider>
        </ThemeProvider>
      )}
    </Router>
  );
};

export default App;
