/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum RoutesEnum {
  AUTH = 'auth',
  PATIENTS = 'patients',
  CAREGIVERS = 'caregivers',
  ORGANIZATION_USERS = 'organization-users',
  DEVICES = 'devices',
  GENERIC_ENTITIES = 'general',
  MEASUREMENTS = 'measurements',
  CHANGE_PASSWORD = 'change-password',
}

export default RoutesEnum;
