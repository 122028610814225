/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import {
  reducer as authReducer,
  getInitialState as getAuthInitialState,
  STATE_KEY as authStateKey,
} from '@biotmed/auth-pages';

import { combinedCrudReducers, combinedCrudInitialStates } from '@biotmed/data-components';

import {
  snackbarReducer,
  SNACKBAR_SLICE_KEY as snackbarStateKey,
  getInitialState as getSnackbarInitialState,
} from '@biotmed/base-components';
import { reducer as dataReducer, STATE_KEY as dataStateKey, getDataInitialState } from '../data';

import { actions as appAction } from '../data/login/index';

const getInitialState = (state?: RootState) => ({
  [snackbarStateKey]: getSnackbarInitialState(),
  [dataStateKey]: getDataInitialState(state),
  [authStateKey]: getAuthInitialState(state?.[authStateKey]),
  ...combinedCrudInitialStates,
});

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const appReducer = combineReducers({
  [snackbarStateKey]: snackbarReducer,
  [dataStateKey]: dataReducer,
  [authStateKey]: authReducer,
  ...combinedCrudReducers,
});

// rootReducer in charge of resetting the store on logout.
// see https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const reducer: typeof appReducer = (state: any, action: { type: string }) => {
  // add logout....
  if (action.type === appAction.logoutFinish.type) {
    const initialState = getInitialState(state) as RootState;
    return appReducer(initialState, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default reducer;
