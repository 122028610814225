import React from 'react';
import { EntityAvatar } from '@biotmed/base-components';
import { EntityType } from '@biotmed/data-components';
import { ChangePasswordPage as ChangePassword } from '@biotmed/auth-pages';
import { useSelector } from 'react-redux';
import { selectors } from '../../redux/data/user';

interface ChangePasswordPageProps {}

export const ChangePasswordPage: React.FC<ChangePasswordPageProps> = props => {
  const userName = useSelector(selectors.selectFullName);
  const userRole = useSelector(selectors.selectUserRole);
  const userType = useSelector(selectors.selectUserType);

  return (
    <ChangePassword
      userName={userName}
      userRole={userRole}
      EntityAvatar={() => <EntityAvatar type={userType as EntityType} />}
    />
  );
};

export default ChangePasswordPage;
