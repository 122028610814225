// eslint-disable-next-line no-use-before-define

import React from 'react';
import AppConfig from 'src/config/AppConfig';
import OrgIcon from '../images/logo.svg';

const OrganizationImage: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = props => (
  <img src={AppConfig.BRANDING_LOGO_URL ? AppConfig.BRANDING_LOGO_URL : OrgIcon} alt="Organization Logo" {...props} />
);

export default OrganizationImage;
