import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';
import { LoginResponse } from '@biotmed/auth-pages';
import { RootState } from 'src/redux/reducer';
import DATA_STATE_KEY from '../../constants';

export const STATE_KEY = 'login';

interface LoginState {
  isLoggedIn: boolean;
}

// eslint-disable-next-line no-unused-vars
export const getInitialState = (state?: any): LoginState => ({
  isLoggedIn: state ? state.isLoggedIn : false,
});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    loginSuccess: state => {
      state.isLoggedIn = true;
      return state;
    },
    loginFail: state => {
      state.isLoggedIn = false;
      return state;
    },
    logoutFinish: state => {
      state.isLoggedIn = false;
      return state;
    },
  },
});
/* eslint-enable no-param-reassign */

const extraActions = {
  loginSuccess: createAction<{ loginResponse: LoginResponse }>(`${STATE_KEY}/loginSuccess`), // handled by user reducer
  loginFail: createAction<string>(`${STATE_KEY}/loginFail`),
  logout: createAction(`${STATE_KEY}/logout`),
  logoutFinish: createAction(`${STATE_KEY}/logoutFinish`), // handled by user reducer
};

export const actions = { ...slice.actions, ...extraActions };

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();

export const selectors = {
  getIsLoggenIn: createSelector(getState, state => state.isLoggedIn),
};

const { reducer } = slice;
export default reducer;
