import React from 'react';
import { Tabs, Icon } from '@biotmed/base-components';
import routes from 'src/routes/Navigation/modules/routes';
import history from 'src/utils/history';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { EntityType, SelfProfile } from '@biotmed/data-components';
import { LanguagePicker } from '@biotmed/i18n';
import AppConfig from 'src/config/AppConfig';
import { actions as loginActions } from '../../../redux/data/login';
import { ReactComponent as LogoIcon } from '../../../images/organizationLogo.svg';
import { LogoContainer, RightContent, TopMenu as TopMenuStyled } from './AppLayout.styled';
import { selectors } from '../../../redux/data/user';
import OrganizationImage from '../../OrganizationImage';

interface TopMenuProps {}

const getCurrentTab = (tabsData: { tabKey: string }[]) => {
  const tabKeys = tabsData.map(tabData => tabData.tabKey);
  const currentLocation = history?.location?.state as string;
  if (currentLocation && !tabKeys.includes(currentLocation)) {
    return false;
  }

  return currentLocation;
};

const TopMenu: React.FC<TopMenuProps> = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const userName = useSelector(selectors.selectFullName);
  const userRole = useSelector(selectors.selectUserRole);
  const userType = useSelector(selectors.selectUserType);

  const handleCallToRouter = (event: React.ChangeEvent<any>, value: string) => {
    history.push(value, value);
  };

  const onDisconnectClick = () => {
    dispatch(loginActions.logout());
  };

  const onChangePasswordClick = () => {
    history.push(routes.CHANGE_PASSWORD, routes.CHANGE_PASSWORD);
  };

  const tabsData = [
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.patients',
        defaultMessage: 'Patients',
      }),
      tabKey: routes.PATIENTS,
    },
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.caregivers',
        defaultMessage: 'Caregivers',
      }),
      tabKey: routes.CAREGIVERS,
    },
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.organization-users',
        defaultMessage: 'Organization Users',
      }),
      tabKey: routes.ORGANIZATION_USERS,
    },
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.devices',
        defaultMessage: 'Devices',
      }),
      tabKey: routes.DEVICES,
    },
    {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.tab.generic-entities',
        defaultMessage: 'General',
      }),
      tabKey: routes.GENERIC_ENTITIES,
    },
  ];

  const currentTab = getCurrentTab(tabsData);

  const rightContent = (
    <RightContent>
      <LanguagePicker availableLanguages={Object.values(AppConfig.AVAILABLE_LOCALES)} />
      <SelfProfile
        orgIcon={OrganizationImage}
        userName={userName}
        userRole={userRole}
        onDisconnectClick={onDisconnectClick}
        userType={userType as EntityType}
        buttons={[
          {
            text: intl.formatMessage({
              id: 'self-profile.change-password',
              defaultMessage: 'Change Password',
            }),
            onClick: onChangePasswordClick,
          },
        ]}
      />
    </RightContent>
  );

  const leftContent = (
    <LogoContainer>
      <Link to="./">
        <Icon height="21px" IconComponent={LogoIcon} color={theme.palette.primary.medium} />
      </Link>
    </LogoContainer>
  );

  return (
    <TopMenuStyled>
      <Tabs
        tabBarExtraContent={{
          left: leftContent,
          right: rightContent,
          spaceBetween: 80,
        }}
        backgroundActive={`${theme.palette.primary.dark}${theme.opacity.almostTransparent}`}
        textColorActive={theme.palette.primary.medium}
        indicatorOverAll
        tabsData={tabsData}
        selectedTabKey={currentTab}
        padding="0 21px"
        onChange={handleCallToRouter}
        variant="scrollable"
        scrollButtons="auto"
      />
    </TopMenuStyled>
  );
};
export default TopMenu;
