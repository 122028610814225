import {
  GenericEntityCrud,
  genericEntityCrudReducer,
  genericEntityCrudSaga,
  genericEntityCrudStateKey,
  genericEntityCrudGetInitialState,
} from '@biotmed/data-components';

export {
  GenericEntityCrud,
  genericEntityCrudReducer,
  genericEntityCrudSaga,
  genericEntityCrudStateKey,
  genericEntityCrudGetInitialState,
};

export default GenericEntityCrud;
