import {
  PatientCrud,
  patientCrudReducer,
  patientCrudSaga,
  patientCrudStateKey,
  patientCrudGetInitialState,
} from '@biotmed/data-components';

export { PatientCrud, patientCrudReducer, patientCrudSaga, patientCrudStateKey, patientCrudGetInitialState };

export default PatientCrud;
