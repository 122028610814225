import { all, AllEffect, call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import ums from '@biot/ums-js-logic';
import { actions } from './slice';

function* logout() {
  try {
    yield call([ums, ums.logout], {});
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in logoutFailed to logout: ', e);
  } finally {
    yield put(actions.logoutFinish());
  }
}
export default function* watch(): Generator<AllEffect<ForkEffect<never>>, void, void> {
  yield all([takeLatest(actions.logout, logout)]);
}
